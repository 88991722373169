<template>
    <b-card>
        <Form
            ref="createRedirect"
            :redirect="redirect"
            :submit="submit"
        />
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EditRedirect",

    components: {
        Form
    },

    data() {
        return {
            id: this.$route.params.id
        }
    },

    computed: {
        ...mapGetters('redirect', [
            'redirectById'
        ]),
        redirect() {
            let redirect = this.redirectById(this.id)
            if (!redirect) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.$router.push({ name: 'redirects' })

                return
            }
            return redirect
        }
    },

    methods: {
        ...mapActions({
            editRedirect: 'redirect/edit',
        }),
        submit() {
            const refRedirect = this.$refs.createRedirect
            refRedirect.errMessage = null

            const data = JSON.parse(JSON.stringify(refRedirect.redirect))
            this.editRedirect(data).then(() => {
                // this.$router.push({ name: 'redirects' })
                this.$swal({
                    icon: 'success',
                    title: this.$t(`Edited!`),
                    text: this.$t('Your file has been edited.'),
                    confirmButtonText: this.$t('Ok!'),
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })
            }).catch(response => {
                if (response.errors) {
                    refRedirect.$refs.form.setErrors(response.errors)
                }
                refRedirect.errMessage = response.message
            })

        }
    }
}
</script>
