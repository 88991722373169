<template>
    <validation-observer ref="form" v-slot="{ invalid }" tag="div">
        <b-overlay :show="loading">
            <b-form @submit.prevent="submit">
                <b-row>
                    <b-col cols="12">
                        <custom-input
                            v-model="redirect.source"
                            :name="$t('source')"
                            dir="ltr"
                            required
                            rules="required"
                            vid="source"
                        />
                    </b-col>
                    <b-col cols="12">
                        <custom-input
                            v-model="redirect.destination"
                            :name="$t('destination')"
                            dir="ltr"
                            required
                            rules="required"
                            vid="destination"
                        />
                    </b-col>
                    <b-col md="2">
                        <custom-select
                            v-model="redirect.type"
                            :name="$t('type')"
                            required
                            rules="required"
                            :options="redirectTypeOptions"
                            vid="type"
                        />
                    </b-col>
                </b-row>
                <b-alert :show="errMessage !== null" class="p-2" dismissible variant="danger">
                    {{ errMessage }}
                </b-alert>

                <b-button :disabled="invalid" class="mt-2" type="submit" variant="primary">
                    {{ $t('save') }}
                </b-button>
            </b-form>
        </b-overlay>
    </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { BAlert } from "bootstrap-vue";
import { redirectTypeOptions } from '@/common/data';

export default {
    name: "Form",

    components: {
        BAlert,
    },

    props: {
        submit: {
            type: Function,
            required: true,
        },
        redirect: {
            default() {
                return {
                    type: '',
                    source: '',
                    destination: '',
                }
            },
            type: Object
        }
    },

    data() {
        return {
            errMessage: null,
            loading: false,
            redirectTypeOptions
        }
    },

    computed: {
        ...mapGetters('redirect', [
            'status'
        ])
    },

}
</script>