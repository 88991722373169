import i18n from "@/libs/i18n";

import CategoryStatus from "@/constans/CategoryStatus";
import PostStatus from "@/constans/PostStatus";
import CommentStatus from "@/constans/CommentStatus";
import RedirectType from "@/constans/RedirectType";

export const QualityListOptions = ['Blu-Ray', 'BDRip', 'BRRip', 'WEB-DL', 'WEBRip', 'HDTV', 'TVRip', 'DVDRip', 'HDCAM', 'HDTS']

export const categoryStatusOptions = [
    {
        value: CategoryStatus.INACTIVE,
        text: i18n.t('category.status.in_active')
    },
    {
        value: CategoryStatus.ACTIVE,
        text: i18n.t('category.status.active')
    },
]

export const postStatusOptions = [
    {
        value: PostStatus.DRAFT,
        text: i18n.t('draft')
    },
    {
        value: PostStatus.PUBLISHED,
        text: i18n.t('publish')
    },
]

export const commentStatusOptions = [
    {
        value: CommentStatus.PENDING,
        text: i18n.t('pending')
    },
    {
        value: CommentStatus.UNAPPROVED,
        text: i18n.t('unapproved')
    },
    {
        value: CommentStatus.APPROVED,
        text: i18n.t('approved')
    },
]
export const redirectTypeOptions = [
    {
        value: RedirectType.REDIRECT_301,
        text: i18n.t('redirect_301')
    },
    {
        value: RedirectType.REDIRECT_302,
        text: i18n.t('redirect_302')
    },
    {
        value: RedirectType.CANONICAL,
        text: i18n.t('canonical')
    },
]